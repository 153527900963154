<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar
      :title="id?'修改车辆信息':'添加车辆'"
      left-arrow
      :fixed="true"
      :z-index="999"
      @click-left="back"
      v-show="!pleshow"
    ></van-nav-bar>

    <div style="padding-top:50px;" v-show="!pleshow">
      <van-field
        v-model="addForm.carNo"
        @click="getple"
        readonly
        label="车牌号"
        placeholder="请输入"
        input-align="right"
      />
      <van-field v-model="addForm.vin" required label="车架号" placeholder="请输入" input-align="right" />
      <van-field v-model="addForm.engineNo" label="发动机号" placeholder="请输入" input-align="right" />
      <van-field
        required
        v-model="chexin"
        @click="tochexin"
        label="车型"
        readonly
        placeholder="请选择"
        input-align="right"
      />
      <van-field
        required
        v-model="addForm.starDriveMiles"
        label="质保起始里程"
        placeholder="请输入"
        input-align="right"
      />
      <van-field
        required
        v-model="addForm.endDriveMiles"
        label="质保终止里程"
        placeholder="请输入"
        input-align="right"
      />
      <van-field
        required
        @click="toriqi(1)"
        readonly
        v-model="addForm.starDate"
        label="质保起始日期"
        placeholder="请选择"
        input-align="right"
      />
      <van-field
        required
        readonly
        @click="toriqi(2)"
        v-model="addForm.endDate"
        label="质保终止日期"
        placeholder="请选择"
        input-align="right"
      />
      <div class="beizhu">
        <div>备注</div>
        <input class="bei" placeholder="请输入" type="text" v-model="addForm.remark" />
      </div>
      <van-field
        required
        v-model="addForm.ownerName"
        label="客户姓名"
        placeholder="请输入"
        input-align="right"
      />
      <van-field
        required
        v-model="addForm.idNumber"
        label="身份证号"
        placeholder="请输入"
        input-align="right"
      />
      <van-field
        required
        v-model="addForm.phone"
        type="tel"
        label="手机号码"
        placeholder="请输入"
        input-align="right"
      />

      <div class="zhenjian">
        <div>证件照(最多9张)</div>
        <div v-for="(item,idx) in addForm.picUrls" :key="idx" class="imgls">
          <img :src="item" @click="getImg(item)" class="imgss" alt />
          <img class="guan" @click.stop="toshanchu(idx)" src="../../assets/img/guan2.png" alt />
        </div>
        <van-uploader
          v-if="addForm.picUrls.length < 8"
          :after-read="onRead"
          multiple
          :max-count="9"
        />
      </div>
      <van-popup v-model="sjshow" position="bottom" :style="{ height: '50%' }">
        <van-datetime-picker
          @cancel="sjshow = false"
          @confirm="sjqueren"
          v-model="currentDate"
          type="date"
          title="选择完整时间"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup>
    </div>
    <div style="height: 60px;" v-show="!pleshow"></div>
    <van-button type="danger" v-if="usedCar != 2" class="bottss" @click="totijiao">提交</van-button>
    <carPlate ref="ple" v-show="pleshow" :plate_number="addForm.carNo" @getPlate="getPlate"></carPlate>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import carPlate from "../../components/carplate.vue";
import {
  uploadPicture,
  ershoucarsave,
  ershoucardetails
} from "../../api/check.js";
import Exif from "exif-js";
import { ImagePreview } from "vant";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {
    carPlate,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      names: this.$route.query.names,
      id: "",
      cid: this.$route.query.cid,
      sellCompany: this.$route.query.sellCompany,
      usedCar: this.$route.query.usedCar,
      value: "",
      imglist: [],
      files: {
        name: "",
        type: ""
      },
      addForm: {
        picUrls: []
      },
      message: "",
      chexin: "",
      carBrandLogo: "",
      actv: 1,
      loading: false,
      pleshow: false,
      active: 1,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      sjshow: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    totijiao() {
      var _this = this;
      if (_this.addForm.vin == "" || !_this.addForm.vin) {
        _this.$toast("请输入车架号");
        return;
      } else if (_this.addForm.vin.length < 17) {
        _this.$toast("请输入17位车架号");
        return;
      } else if (_this.chexin == "" || !_this.chexin) {
        _this.$toast("请先填写车型");
        return;
      } else if (
        _this.addForm.starDriveMiles == "" ||
        !_this.addForm.starDriveMiles
      ) {
        _this.$toast("请先填写质保起始里程");
        return;
      } else if (
        _this.addForm.endDriveMiles == "" ||
        !_this.addForm.endDriveMiles
      ) {
        _this.$toast("请先填写质保终止里程");
        return;
      } else if (_this.addForm.starDate == "" || !_this.addForm.starDate) {
        _this.$toast("请先填写质保起始日期");
        return;
      } else if (_this.addForm.endDate == "" || !_this.addForm.endDate) {
        _this.$toast("请先填写质保终止日期");
        return;
      } else if (_this.addForm.phone == "" || !_this.addForm.phone) {
        _this.$toast("请输入手机号码");
        return;
      } else if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
        _this.$toast("手机号码格式错误");
        return;
      } else if (_this.addForm.ownerName == "" || !_this.addForm.ownerName) {
        _this.$toast("请输入您的姓名");
        return;
      } else if (_this.addForm.idNumber == "" || !_this.addForm.idNumber) {
        _this.$toast("请输入您的身份证号");
        return;
      } else if (
        !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(_this.addForm.idNumber)
      ) {
        _this.$toast("您的身份证格式不正确");
        return;
      }
      if (_this.chexin == "" || !_this.chexin) {
      } else {
        var chexin = this.chexin.split("-");
      }

      var data = {
        carBrand: chexin[0],
        standard: chexin[1],
        carBrandLogo: this.carBrandLogo,
        sellCompany: this.sellCompany,
        cid: this.cid,
        gid: this.shangjia.id,
        ..._this.addForm
      };
      // console.log(da)

      //  else if (_this.addForm.carNo.length < 6) {
      //   _this.$toast("请填写车牌号码");
      //   return;
      // }

      ershoucarsave(data)
        .then(e => {
          if (e.code == 200) {
            _this.$toast("提交成功");

            setTimeout(() => {
              _this.$router.go(-1);
            }, 900);
          }
        })
        .catch();
    },
    //删除图片
    toshanchu(idx) {
      this.addForm.picUrls.splice(idx, 1);
    },
    //点击放大图片
    getImg(images) {
      ImagePreview([images]);
    },
    // 组件方法 获取 流
    async onRead(file) {
      var _this = this;
      // if (_this.addForm.phone == "" || !_this.addForm.phone) {
      //   _this.$toast("请先输入手机号码");
      //   return;
      // } else if (!/^1[3456789]\d{9}$/.test(_this.addForm.phone)) {
      //   _this.$toast("手机号码格式错误");
      //   return;
      // }
      console.log(file);
      // console.log(file.file);
      this.files.name = file.file.name; // 获取文件名
      this.files.type = file.file.type; // 获取类型
      this.picValue = file.file; // 文件流
      this.imgPreview(this.picValue);
    },
    // 处理图片
    imgPreview(file) {
      let self = this;
      try {
        // 你的逻辑代码
        let Orientation;
        //去获取拍照时的信息，解决拍出来的照片旋转问题
        Exif.getData(file, function() {
          Orientation = Exif.getTag(this, "Orientation");
        });
        // 看支持不支持FileReader
        if (!file || !window.FileReader) return;
        if (/^image/.test(file.type)) {
          // 创建一个reader
          let reader = new FileReader();
          // 将图片2将转成 base64 格式
          reader.readAsDataURL(file);
          // 读取成功后的回调
          reader.onloadend = function() {
            // console.log(this.result);
            let result = this.result;
            let img = new Image();
            img.src = result;
            //判断图片是否大于500K,是就直接上传，反之压缩图片
            if (this.result.length <= 500 * 1024) {
              self.headerImage = this.result;
              self.postImg();
            } else {
              img.onload = function() {
                let data = self.compress(img, Orientation);
                self.headerImage = data;
                self.postImg();
              };
            }
          };
        }
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
      }
    },
    // 压缩图片
    compress(img, Orientation) {
      try {
        // 你的逻辑代码
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        //瓦片canvas
        let tCanvas = document.createElement("canvas");
        let tctx = tCanvas.getContext("2d");
        // let initSize = img.src.length;
        let width = img.width;
        let height = img.height;
        //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
        let ratio;
        if ((ratio = (width * height) / 2000000) > 1) {
          // console.log("大于400万像素");
          ratio = Math.sqrt(ratio);
          width /= ratio;
          height /= ratio;
        } else {
          ratio = 1;
        }
        canvas.width = width;
        canvas.height = height;
        //    铺底色
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        //如果图片像素大于100万则使用瓦片绘制
        let count;
        if ((count = (width * height) / 1000000) > 1) {
          // console.log("超过100W像素");
          count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
          //      计算每块瓦片的宽和高
          let nw = ~~(width / count);
          let nh = ~~(height / count);
          tCanvas.width = nw;
          tCanvas.height = nh;
          for (let i = 0; i < count; i++) {
            for (let j = 0; j < count; j++) {
              tctx.drawImage(
                img,
                i * nw * ratio,
                j * nh * ratio,
                nw * ratio,
                nh * ratio,
                0,
                0,
                nw,
                nh
              );
              ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
            }
          }
        } else {
          ctx.drawImage(img, 0, 0, width, height);
        }
        //修复ios上传图片的时候 被旋转的问题
        if (Orientation != "" && Orientation != 1) {
          switch (Orientation) {
            case 6: //需要顺时针（向左）90度旋转
              this.rotateImg(img, "left", canvas);
              break;
            case 8: //需要逆时针（向右）90度旋转
              this.rotateImg(img, "right", canvas);
              break;
            case 3: //需要180度旋转
              this.rotateImg(img, "right", canvas); //转两次
              this.rotateImg(img, "right", canvas);
              break;
          }
        }
        //进行最小压缩
        let ndata = canvas.toDataURL("image/jpeg", 0.1);
        tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
        return ndata;
      } catch (e) {
        // 错误处理代码片段

        console.log(e);
      }
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      try {
        // 你的逻辑代码
        //最小与最大旋转方向，图片旋转4次后回到原方向
        const min_step = 0;
        const max_step = 3;
        if (img == null) return;
        //img的高度和宽度不能在img元素隐藏后获取，否则会出错
        let height = img.height;
        let width = img.width;
        let step = 2;
        if (step == null) {
          step = min_step;
        }
        if (direction == "right") {
          step++;
          //旋转到原位置，即超过最大值
          step > max_step && (step = min_step);
        } else {
          step--;
          step < min_step && (step = max_step);
        }
        //旋转角度以弧度值为参数
        let degree = (step * 90 * Math.PI) / 180;
        let ctx = canvas.getContext("2d");
        switch (step) {
          case 0:
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0);
            break;
          case 1:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate(degree);
            ctx.drawImage(img, 0, -height);
            break;
          case 2:
            canvas.width = width;
            canvas.height = height;
            ctx.rotate(degree);
            ctx.drawImage(img, -width, -height);
            break;
          case 3:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate(degree);
            ctx.drawImage(img, -width, 0);
            break;
        }
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
      }
    },
    //将base64转换为文件
    dataURLtoFile(dataurl) {
      try {
        // 你的逻辑代码
        var arr = dataurl.split(","),
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], this.files.name, {
          type: this.files.type
        });
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
      }
    },
    //这里写接口
    async postImg() {
      var _this = this;
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      try {
        // 你的逻辑代码
        let file = this.dataURLtoFile(this.headerImage);
        let formData = new window.FormData();
        formData.append("file", file);
        console.log(file);
        uploadPicture(formData)
          .then(e => {
            loading.clear();
            if (e.code == 200) {
              this.addForm.picUrls.push(e.data);
            }
          })
          .catch();
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },

    //去修改日期
    toriqi(item) {
      this.active = item;
      this.sjshow = true;
    },
    getPlate(val) {
      console.log(val);
      this.addForm.carNo = val;
      this.pleshow = false;
    },
    sjqueren(value) {
      let sday = this.formatTime(value, "yyyy-MM-dd");
      if (this.active == 1) {
        this.addForm.starDate = sday;
      } else {
        this.addForm.endDate = sday;
      }

      this.sjshow = false;
      console.log(sday);
    },
    formatTime: function(date, fmt) {
      var date = new Date(date);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      var o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          var str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : ("00" + str).substr(str.length)
          );
        }
      }
      return fmt;
    },
    //打开键盘
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    //跳转选择车型
    tochexin() {
      this.$router.push({
        path: "model_list"
      });
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        id: this.id
      };
      ershoucardetails(data)
        .then(e => {
          loading.clear();
          this.addForm = e.data;
          if (this.addForm.picUrls == null) {
            this.addForm.picUrls = [];
          }
          this.chexin = e.data.carBrand + "-" + e.data.standard;
          this.carBrandLogo = e.data.carBrandLogo;
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.isFirstEnter = true;
  },
  mounted() {
    // this.backziding();
  },
  activated() {
    var _this = this;
    if (this.$route.meta.isBack || this.isFirstEnter) {
      console.log("zoule");
      if (this.$route.query.names == "修改") {
        _this.id = this.$route.query.id;
        _this.onLoad();
      } else {
        _this.chexin = "";
        _this.id = "";
        _this.addForm = {
          picUrls: []
        };
        _this.carBrandLogo = "";
      }
    } else {
    }
    var che = JSON.parse(sessionStorage.getItem("che"));
    // this.chexin
    if (che != null) {
      this.carBrandLogo = che.carBrandLogo;
      this.chexin = che.carBrand + "-" + che.carModel;
    }

    console.log(che);
    sessionStorage.removeItem("che");
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false;
    //this.isBack = false;
    this.isFirstEnter = false;
  }
};
</script>

<style lang="less" scoped>
.hset {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 46px;
  overflow: hidden;
}
.hes_a {
  width: 50%;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  float: left;
  color: #666;
  position: relative;
}
.xian {
  background: #0d906e;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1.5px;
  margin-bottom: 13px;
  width: 40%;
  margin-left: -20%;
}
.cors {
  color: #0d906e;
}
.mian {
  background: rgb(242, 251, 252);
  margin: 0 15px;
  text-align: left;
  border-radius: 10px;
  font-size: 15px;
  padding: 15px 15px 25px 15px;
  color: #666;
}
.tesx {
  // padding-left: 15px;
  padding: 2px 0 2px 15px;
}
.dian {
  font-weight: bold;
  margin-right: 5px;
  color: #000;
  float: left;
}
.ban {
  // font-weight: bold;
  color: #000;
  font-size: 16px;
  margin-bottom: 2px;
}
.anniu {
  position: absolute;
  bottom: 0;
  width: 90%;
  height: 40px;
  left: 50%;
  margin-left: -45%;
  margin-bottom: 70px;
}
.bottss {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.beizhu {
  padding: 10px 15px;
  font-size: 14px;
  border-bottom: 5px solid #f5f5f5;
}
.bei {
  border: none;
  padding: 0 5px;
  width: 100%;
  margin-top: 4px;
}
.zhenjian {
  font-size: 14px;
  padding: 10px 15px;
}
.van-uploader {
  margin-top: 8px;
}
.imgls {
  width: 80px;
  height: 80px;
  margin-top: 8px;
  float: left;
  margin-right: 8px;
  position: relative;
}
.imgss {
  width: 100%;
  height: 80px;
}
.guan {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
}
</style>
<style >
.van-field__label {
  color: #000 !important;
}
</style>